<template>
    <el-row style="height: 100%">
        <el-col :span="24">
            <el-card style="height: 100%">
                <el-row>
                    <el-col :span="20">
                    </el-col>
                    <el-col :span="4">
                        <el-input
                                v-model="searchContent"
                                placeholder="搜索..."
                                size="mini"
                                style="float:right;">
                        </el-input>
                    </el-col>
                </el-row>
                <el-row style="margin-top: 10px;">
                    <el-table
                            size="mini"
                            :data="tableData.slice((currentPage-1)*pageSize,currentPage*pageSize)"
                            stripe
                            v-loading="loading"
                            height="700"
                            style="width: 100%;">
                        <el-table-column prop="name" label="学员名称" show-overflow-tooltip>
                        </el-table-column>
                        <el-table-column prop="phone" label="手机号" show-overflow-tooltip>
                        </el-table-column>
                        <el-table-column prop="title" label="题目" show-overflow-tooltip>
                        </el-table-column>
                        <el-table-column prop="create_time" label="答题时间" width="180" show-overflow-tooltip>
                        </el-table-column>
                        <el-table-column label="是否正确" show-overflow-tooltip>
                            <template #default="scope">
                                {{ scope.row.result ? '是' : '否' }}
                            </template>
                        </el-table-column>
                    </el-table>
                </el-row>
                <el-row style="margin-top: 10px;float: right">
                    <el-pagination
                            background
                            @size-change="handleSizeChange"
                            @current-change="handleCurrentChange"
                            :current-page="currentPage"
                            :page-sizes="[10, 50, 100, 500, 1000]"
                            :page-size="pageSize"
                            layout="total, sizes, prev, pager, next, jumper"
                            :total="tableData.length">
                    </el-pagination>
                </el-row>
            </el-card>
        </el-col>
    </el-row>
</template>

<script>
    import axiosApi from "../http";

    export default {
        name: "ZjRecord",
        data() {
            return {
                users:[],
                tableData: [],
                loading: false,
                searchContent: '',
                currentPage: 1,
                pageSize: 10,
            };
        },
        mounted() {
            this.getData();
        },
        watch: {
            searchContent: function () {
                let input = this.searchContent;
                let items = this.users;
                if (input) {
                    this.tableData = items.filter(function (item) {
                        return Object.keys(item).some(function (key1) {
                            return String(item[key1]).match(input);
                        });
                    });
                } else {
                    this.tableData = items;
                }
            }
        },
        methods: {
            getData() {
                this.loading = true;
                axiosApi("/api/sys/zjRecord/list", null, "get").then((data) => {
                    this.users = data;
                    this.tableData = data;
                    this.loading = false;
                });
            },
            handleSizeChange(val) {
                if (val != null) {
                    this.pageSize = val;
                }
            },
            handleCurrentChange(val) {
                if (val != null) {
                    this.currentPage = val;
                }
            },
        }
    }
</script>

<style scoped>

</style>