<template>
    <el-row style="height: 100%">
        <el-col :span="24">
            <el-card style="height: 100%">
                <el-row style="margin-top: 10px;">
                    <el-table
                            size="mini"
                            :data="tableData"
                            stripe
                            v-loading="loading"
                            height="700px"
                            style="width: 100%">
                        <el-table-column prop="referralName" label="推荐人姓名" show-overflow-tooltip>
                        </el-table-column>
                        <el-table-column prop="referralPhone" label="推荐人手机号" show-overflow-tooltip>
                        </el-table-column>
                        <el-table-column prop="name" label="被推荐人姓名" show-overflow-tooltip>
                        </el-table-column>
                        <el-table-column prop="phone" label="被推荐人手机号" show-overflow-tooltip>
                        </el-table-column>
                        <el-table-column label="是否推荐成功" show-overflow-tooltip>
                            <template #default="scope">
                                {{ scope.row.status === '1' ? '是' : '否' }}
                            </template>
                        </el-table-column>
                        <el-table-column label="操作">
                            <template #default="scope">
                                <el-link :underline="false" href="javascript:void(0)" type="primary"
                                         v-if="scope.row.status === '0'"
                                         @click="confirmRef(scope.row)"
                                         style="font-size: 13px">
                                    确认推荐成功
                                </el-link>
                            </template>
                        </el-table-column>
                    </el-table>
                </el-row>
            </el-card>
        </el-col>
    </el-row>
</template>

<script>
    import axiosApi from "../http";

    export default {
        name: "Referral",
        data() {
            return {
                tableData: [],
                loading: false,
            };
        },
        mounted() {
            this.getRefs();
        },
        methods: {
            confirmRef(row) {
                this.$confirm('确认被推荐人“' + row.name + '”已推荐成功吗?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(({}) => {
                    axiosApi("/api/sys/referral/update", {id: row.id, status: '1'}, "post").then(() => {
                        this.$message({
                            type: 'success',
                            message: '确认成功！'
                        });
                        this.getRefs();
                    });
                });
            },
            getRefs() {
                this.loading = true;
                axiosApi("/api/sys/referral/list", null, "get").then((data) => {
                    this.tableData = data;
                    this.loading = false;
                });
            },
        }
    }
</script>

<style scoped>

</style>