<template>
    <el-row style="height: 100%">
        <el-col :span="6" style="height: 100%">
            <el-card style="height: 100%;margin-right: 10px;">
                <el-select size="mini" v-model="value" filterable placeholder="选择试卷分配对应习题，必须选中100道！"
                           clearable
                           @change="selectChange"
                           style="width: 100%;">
                    <el-option
                            v-for="item in options"
                            :key="item.id"
                            :label="item.examName"
                            :value="item.id">
                    </el-option>
                </el-select>
                <el-tree style="font-size: 13px"
                         :props="props"
                         :load="loadNode"
                         node-key="id"
                         :highlight-current="true"
                         :expand-on-click-node="false"
                         @node-click="clickNode"
                         ref="tree"
                         default-expand-all
                         lazy>
                    <template #default="{ node, data }">
              <span class="custom-tree-node show-hide" style="width: 100%">
                <span>{{ node.label }}</span>
                <span style="float: right; display: none">
                  <template v-if="!data.leaf">
                    <el-tooltip :content="data.id === 0?'添加科目':'添加章节'" placement="top">
                        <a class="el-icon-plus"
                           @click="addNode(node, data)">
                        </a>
                    </el-tooltip>
                  </template>
                  <template v-if="data.id !== 0">
                    <el-tooltip content="编辑" placement="top">
                        <a class="el-icon-edit" @click="editNode(node, data)"></a>
                     </el-tooltip>
                     <el-tooltip content="删除" placement="top">
                       <a class="el-icon-delete" @click="deleteNode(node, data)"></a>
                     </el-tooltip>
                  </template>

                </span>
              </span>
                    </template>
                </el-tree>
            </el-card>
        </el-col>
        <el-col :span="18" style="height: 100%">
            <el-card style="height: 100%">
                <el-row>
                    <el-form size="mini" :inline="true" :model="query" class="demo-form-inline">
                        <el-form-item label="出题人">
                            <el-input v-model="query.user_id" placeholder="题目创建人..."></el-input>
                        </el-form-item>
                        <el-form-item label="题型">
                            <el-select v-model="query.t_type" placeholder="题型" style="width: 100px">
                                <el-option label="ALL" :value="0"></el-option>
                                <el-option label="判断题" :value="1"></el-option>
                                <el-option label="单选题" :value="2"></el-option>
                                <el-option label="多选题" :value="3"></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="题目">
                            <el-input v-model="query.title" placeholder="题目，支持模糊匹配..." style="width: 300px"></el-input>
                        </el-form-item>
                        <el-form-item>
                            <el-button type="primary" @click="onQuery">查询</el-button>
                        </el-form-item>
                    </el-form>
                </el-row>
                <el-row>
                    <el-drawer
                            v-model="drawer"
                            :with-header="false" size="50%">
                        <el-form style="margin-top: 20px;margin-right: 10px" size="mini" :model="xiti" :rules="rules"
                                 ref="ruleForm"
                                 label-width="150px" class="demo-ruleForm">
                            <el-form-item label="题型" prop="t_type">
                                <el-radio-group v-model="xiti.t_type">
                                    <el-radio :label="1">判断题</el-radio>
                                    <el-radio :label="2">单选题</el-radio>
                                    <el-radio :label="3">多选题</el-radio>
                                </el-radio-group>
                            </el-form-item>
                            <el-form-item label="题目" prop="title">
                                <el-input v-model="xiti.title"></el-input>
                            </el-form-item>
                            <template v-if="xiti.t_type !== 1">
                                <el-form-item label="选项">
                                    <el-row v-for="(item, index) in xiti.content" style="margin-top: 5px">
                                        <el-col :span="1" style="text-align: right;margin-right: 10px">
                                            <span>{{ answerName[index] }}</span>
                                        </el-col>
                                        <el-col :span="18">
                                            <el-form-item :prop="'content.' + index + '.value'" :rules="rules.content"
                                                          :key="index">
                                                <el-input type="textarea" v-model="item.value"></el-input>
                                            </el-form-item>
                                        </el-col>
                                        <el-col :span="2" style="text-align: center">
                                            <el-link href="#" :underline="false" type="primary" class="el-icon-plus"
                                                     @click="addXX(index)"
                                                     style="margin-left: 5px"></el-link>
                                            <el-link href="#" :underline="false" type="danger" class="el-icon-delete"
                                                     @click="deleteXX(index)"
                                                     style="margin-left: 5px"></el-link>
                                        </el-col>
                                    </el-row>
                                </el-form-item>
                            </template>
                            <template v-if="xiti.t_type === 3">
                                <el-form-item label="答案" prop="answers">
                                    <el-checkbox-group v-model="xiti.answers">
                                        <el-checkbox v-for="(item, index) in xiti.content" :label="index+1">{{
                                            answerName[index]
                                            }}
                                        </el-checkbox>
                                    </el-checkbox-group>
                                </el-form-item>
                            </template>
                            <template v-if="xiti.t_type === 2">
                                <el-form-item label="答案" prop="answer">
                                    <el-radio-group v-model="xiti.answer">
                                        <el-radio v-for="(item, index) in xiti.content" :label="index+1">{{
                                            answerName[index] }}
                                        </el-radio>
                                    </el-radio-group>
                                </el-form-item>
                            </template>
                            <template v-if="xiti.t_type === 1">
                                <el-form-item label="答案" prop="answer">
                                    <el-radio-group v-model="xiti.answer">
                                        <el-radio :label="1">对</el-radio>
                                        <el-radio :label="2">错</el-radio>
                                    </el-radio-group>
                                </el-form-item>
                            </template>
                            <el-form-item label="解析" prop="analysis">
                                <el-input type="textarea" v-model="xiti.analysis"></el-input>
                            </el-form-item>
                            <template v-if="xiti.id !== 0">
                                <el-form-item label="出题人">
                                    {{ xiti.user_id }}
                                </el-form-item>
                                <el-form-item label="出题时间">
                                    {{ xiti.create_time }}
                                </el-form-item>
                                <el-form-item label="最后修改人">
                                    {{ xiti.update_user_id }}
                                </el-form-item>
                                <el-form-item label="最后修改时间">
                                    {{ xiti.update_time }}
                                </el-form-item>
                            </template>
                            <el-form-item>
                                <el-button type="primary" @click="submitForm('ruleForm')">确定</el-button>
                                <el-button @click="resetForm('ruleForm')">重置</el-button>
                            </el-form-item>
                        </el-form>
                    </el-drawer>
                    <el-drawer
                            v-model="upload"
                            :with-header="false">
                        <el-form style="margin-top: 20px;margin-right: 10px" size="mini"
                                 label-width="150px">
                            <el-form-item label="所选章节">
                                {{ uploadXJ.name }}
                            </el-form-item>
                            <el-form-item label="选择文件">
                                <el-upload
                                        accept=".xlsx"
                                        ref="upload"
                                        class="upload-demo"
                                        :action="'/api/sys/tiku/uploadXiti/'+uploadXJ.id"
                                        drag
                                        :before-upload="beforeUpload"
                                        :on-success="uploadSuccess"
                                        :on-error="uploadError"
                                >
                                    <i class="el-icon-upload"></i>
                                    <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
                                    <template #tip>
                                        <div class="el-upload__tip">
                                            <span>只能上传xlsx文件，文件大小不能超过100MB</span>
                                        </div>
                                        <el-link size="mini" href="/api/sys/tiku/downloadTemplate" type="primary"
                                                 :underline="false">下载模板
                                        </el-link>
                                    </template>
                                </el-upload>
                            </el-form-item>
                        </el-form>

                    </el-drawer>
                    <el-button size="mini" type="primary" @click="onAddXiti">添加</el-button>
                    <el-button size="mini" type="success" @click="importXiti">导入</el-button>
                    <el-button size="mini" type="danger" :disabled="multipleSelection.length === 0" @click="deleteXiti">
                        {{ multipleSelection.length === 0 ? '删除' : '删除 (' + multipleSelection.length + ')' }}
                    </el-button>
                    <el-button size="mini" type="primary" @click="updateExamXiti" :disabled="selectIds.length !== 100">
                        保存习题到试卷({{selectIds.length}})
                    </el-button>
                </el-row>
                <el-row style="margin-top: 10px;">
                    <el-table
                            size="mini"
                            :data="tableData"
                            ref="table"
                            stripe
                            v-loading="loadingTable"
                            @selection-change="handleSelectionChange"
                            row-key="id"
                            height="640"
                            style="width: 100%;">
                        <el-table-column type="selection" width="55px"></el-table-column>
                        <el-table-column prop="user_id" label="出题人" width="100" show-overflow-tooltip>
                        </el-table-column>
                        <el-table-column prop="create_time" label="出题时间" width="150" show-overflow-tooltip>
                        </el-table-column>
                        <el-table-column label="题型" width="100">
                            <template #default="scope">
                                {{ getTType(scope.row.t_type) }}
                            </template>
                        </el-table-column>
                        <el-table-column label="题目" show-overflow-tooltip>
                            <template #default="scope">
                                <el-link :underline="false" href="#" type="primary" @click="lookXiti(scope.row)"
                                         style="font-size: 13px">
                                    {{ scope.row.title }}
                                </el-link>
                            </template>
                        </el-table-column>
                    </el-table>
                </el-row>
                <el-row style="margin-top: 10px;float: right">
                    <el-pagination
                            background
                            @size-change="handleSizeChange"
                            @current-change="handleCurrentChange"
                            :current-page="currentPage"
                            :page-sizes="[10, 50, 100, 500, 1000]"
                            :page-size="pageSize"
                            layout="total, sizes, prev, pager, next, jumper"
                            :total="totalCount">
                    </el-pagination>
                </el-row>
            </el-card>
        </el-col>
    </el-row>
</template>

<script>
    import axiosApi from "@/http";
    import VueResizable from "vue-resizable";

    export default {
        name: "Tiku",
        components: {
            VueResizable
        },
        data() {
            return {
                multipleSelection:[],
                selectIds: [],
                answerName: ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H'],
                xiti: {},
                rules: {
                    t_type: [
                        {required: true, message: '请选择题型', trigger: 'change'},
                    ],
                    title: [
                        {required: true, message: '请输入题目', trigger: 'blur'},
                        {max: 100, message: '长度最大100个字符', trigger: 'blur'}
                    ],
                    content: [
                        {required: true, message: '请输入选项内容', trigger: 'blur'},
                        {max: 100, message: '长度最大100个字符', trigger: 'blur'}
                    ],
                    analysis: [
                        {min: 0, max: 100, message: '长度最大300个字符', trigger: 'blur'}
                    ],
                    answer: [
                        {required: true, message: '请选择答案', trigger: 'change'}
                    ],
                    answers: [
                        {type: 'array', required: true, message: '请选择答案', trigger: 'change'},
                    ],
                },
                upload: false,
                uploadXJ: {},
                drawer: false,
                query: {
                    zj_id: 0,
                    user_id: '',
                    t_type: 0,
                    title: '',
                },
                props: {
                    label: 'zj_name',
                    children: 'zones',
                    isLeaf: 'leaf'
                },
                tableData: [],
                loadingTable: false,
                currentPage: 1,
                pageSize: 10,
                totalCount: 0,
                options: [],
                value: '',
            };
        },
        mounted() {
            this.initXiti();
            this.onQuery();
            this.getExams();
        },
        methods: {
            updateExamXiti() {
                axiosApi("/api/sys/exam/update", {
                    id: this.value,
                    xitiList: this.selectIds.join(",")
                }, "post").then(() => {
                    this.$message({
                        type: 'success',
                        message: '保存成功！'
                    });
                });
            },
            selectChange(id) {
                if(id){
                    this.loadingTable = true;
                    this.$refs.table.clearSelection();
                    axiosApi("/api/sys/exam/findExam/" + id, null, "get").then((data) => {
                        let ids = [];
                        for (let i = 0; i < data.length; i++) {
                            ids.push(data[i].id);
                        }
                        for (let i = 0; i < this.tableData.length; i++) {
                            if (ids.indexOf(this.tableData[i].id) > -1) {
                                this.$refs['table'].toggleRowSelection(this.tableData[i], true);
                            }
                        }
                        this.selectIds = ids;
                        this.loadingTable = false;
                    });
                }else{
                    this.$refs['table'].clearSelection();
                    this.selectIds = [];
                }
            },
            getExams() {
                axiosApi("/api/sys/exam/list", null, "get").then((data) => {
                    this.options = data;
                });
            },
            handleSelectionChange(val) {
                this.multipleSelection = val;
                if (this.value === '') {
                    return;
                }
                let rids = [];
                for (let i = 0; i < this.tableData.length; i++) {
                    rids.push(this.tableData[i].id);
                }
                let ids = [];
                for (let i = 0; i < val.length; i++) {
                    ids.push(val[i].id);
                }
                let newIds = [];
                for (let i = 0; i < this.selectIds.length; i++) {
                    let id = this.selectIds[i];
                    if (rids.indexOf(id) === -1) {
                        newIds.push(id);
                    }
                }
                for (let i = 0; i < ids.length; i++) {
                    let id = ids[i];
                    if (newIds.indexOf(id) === -1) {
                        newIds.push(id);
                    }
                }
                this.selectIds = newIds;
            },
            initXiti() {
                this.xiti = {
                    id: 0,
                    zj_id: 0,
                    user_id: '曹文豪',
                    t_type: 1,
                    title: '',
                    content: [{value: ''}, {value: ''}, {value: ''}, {value: ''}],
                    answer: 1,
                    answers: [],
                    analysis: '',
                };
            },
            lookXiti(row) {
                this.xiti = Object.assign({}, row);
                if (this.xiti.t_type !== 1) {
                    this.xiti.content = JSON.parse(this.xiti.content);
                    if (this.xiti.t_type === 2) {
                        this.xiti.answer = Number(this.xiti.answer);
                    } else {
                        this.xiti.answers = JSON.parse(this.xiti.answer);
                    }
                } else {
                    this.xiti.content = [{value: ''}, {value: ''}, {value: ''}, {value: ''}];
                    this.xiti.answer = Number(this.xiti.answer);
                }
                this.drawer = true;
            },
            uploadError(err, file, fileList) {
                this.$message({
                    message: "网络请求异常，文件上传失败：" + file.name + '，失败原因：' + err,
                    type: 'error'
                })
                file.status = 'error'
                file.name = `${file.name}（上传失败）`
            },
            uploadSuccess(response, file, fileList) {
                if (response.code === 0) {
                    this.$message({
                        message: "文件上传成功：" + file.name,
                        type: 'success'
                    });
                    this.upload = false;
                    this.onQuery();
                } else {
                    this.$message({
                        message: "文件上传失败：" + file.name + '，失败原因：' + response.error,
                        type: 'error'
                    })
                    file.status = 'error'
                    file.name = `${file.name}（上传失败）`
                }
            },
            beforeUpload(file) {
                const hz = file.name.substring(file.name.lastIndexOf('.') + 1);
                const extension = hz === 'xlsx';
                const isLt100M = file.size / 1024 / 1024 < 100
                if (!extension) {
                    this.$message({
                        message: "上传文件只能是xlsx格式！",
                        type: 'error'
                    })
                    return false;
                }
                if (!isLt100M) {
                    this.$message({
                        message: "上传文件大小不能超过100M！",
                        type: 'error'
                    })
                    return false;
                }
                return true;
            },
            deleteXiti() {
                this.$confirm('此操作将永久删除选中的所有习题，是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(({}) => {
                    let ids = [];
                    for (let i = 0; i < this.multipleSelection.length; i++) {
                        ids.push(this.multipleSelection[i].id);
                    }
                    axiosApi("/api/sys/tiku/deleteXiti", ids, "post").then(() => {
                        this.$message({
                            type: 'success',
                            message: '删除成功！'
                        });
                        this.onQuery();
                    });
                });
            },
            importXiti() {
                let node = this.$refs.tree.getCurrentNode();
                if (node === null || !node.leaf) {
                    this.$message({
                        type: 'warning',
                        message: '请先选择一个章节！'
                    });
                } else {
                    this.uploadXJ.id = node.id;
                    this.uploadXJ.name = node.zj_name;
                    this.upload = true;
                    try {
                        this.$refs.upload.clearFiles();
                    } catch (e) {
                    }
                }
            },
            onAddXiti() {
                let node = this.$refs.tree.getCurrentNode();
                if (node === null || !node.leaf) {
                    this.$message({
                        type: 'warning',
                        message: '请先选择一个章节！'
                    });
                } else {
                    this.xiti.id = 0;
                    this.xiti.zj_id = node.id;
                    this.drawer = true;
                }
            },
            getTType(t_type) {
                switch (t_type) {
                    case 1:
                        return '判断题';
                    case 2:
                        return '单择题';
                    case 3:
                        return '多选题';
                }
                return '未知题型';
            },
            clickNode(data, node, obj) {
                this.onQuery();
            },
            onQuery() {
                let id = this.$refs.tree.getCurrentKey();
                if (id == null) id = 0;
                this.query.zj_id = id;
                let data = Object.assign({}, this.query);
                data.currentPage = this.currentPage;
                data.pageSize = this.pageSize;
                this.loadingTable = true;
                axiosApi("/api/sys/tiku/queryXiti", data, "post").then((data) => {
                    this.tableData = data[0];
                    this.totalCount = data[1][0];
                    this.loadingTable = false;
                    let ids = Object.assign([], this.selectIds);
                    this.$refs.table.clearSelection();
                    for (let i = 0; i < this.tableData.length; i++) {
                        if (ids.indexOf(this.tableData[i].id) > -1) {
                            this.$refs['table'].toggleRowSelection(this.tableData[i], true);
                        }
                    }
                });
            },
            submitForm(formName) {
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        let data = Object.assign({}, this.xiti);
                        if (data.id !== 0) {
                            axiosApi("/api/sys/tiku/updateXiti", data, "post").then(() => {
                                this.$message({
                                    type: 'success',
                                    message: '修改成功！'
                                });
                                this.drawer = false;
                                this.onQuery();
                            });
                        } else {
                            axiosApi("/api/sys/tiku/addXiti", data, "post").then(() => {
                                this.$message({
                                    type: 'success',
                                    message: '添加成功！'
                                });
                                this.drawer = false;
                                this.onQuery();
                            });
                        }
                    } else {
                        return false;
                    }
                });
            },
            resetForm(formName) {
                let type = this.xiti.t_type;
                this.$refs[formName].resetFields();
                this.xiti.t_type = type;
                this.xiti.content = [{value: ''}, {value: ''}, {value: ''}, {value: ''}];
            },
            addXX(index) {
                if (this.xiti.content.length === 8) {
                    this.$message({
                        type: 'warning',
                        message: '最多支持填写8个选项！'
                    });
                } else {
                    this.xiti.content.splice(index + 1, 0, {value: ''});
                }
            },
            deleteXX(index) {
                if (this.xiti.content.length === 2) {
                    this.$message({
                        type: 'warning',
                        message: '选择题至少有两个选项！'
                    });
                } else {
                    this.xiti.content.splice(index, 1);
                }
            },
            handleSizeChange(val) {
                if (val != null) {
                    this.pageSize = val;
                    this.onQuery();
                }
            },
            handleCurrentChange(val) {
                if (val != null) {
                    this.currentPage = val;
                    this.onQuery();
                }
            },
            loadNode(node, resolve) {
                if (node.level === 0) {
                    return resolve([{id: 0, zj_name: '所有题库', show: false}]);
                }
                axiosApi("/api/sys/tiku/getClassifyChildren/" + node.data.id, {}, "get").then((data) => {
                    resolve(data);
                });
            },
            deleteNode(node, data) {
                let msg = "此操作将永久删除章节《" + data.zj_name + "》以及所属的所有习题, 是否继续?";
                if (data.parent_id === 0) {
                    msg = "此操作将永久删除科目《" + data.zj_name + "》以及所属的所有章节和习题, 是否继续?";
                }
                this.$confirm(msg, '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(({}) => {
                    let json = data.id;
                    axiosApi("/api/sys/tiku/deleteClassify", json, "post").then(() => {
                        this.$message({
                            type: 'success',
                            message: '删除成功！'
                        });
                        node.parent.loaded = false
                        node.parent.expand();
                        this.onQuery();
                    });
                });
            },
            editNode(node, data) {
                this.$prompt("请输入新的名称", '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    inputValue: data.zj_name,
                    inputValidator: (input) => {
                        return input.length <= 20
                    },
                    inputErrorMessage: '超出长度限制'
                }).then(({value}) => {
                    let json = {
                        zj_name: value,
                        id: data.id
                    };
                    axiosApi("/api/sys/tiku/updateClassify", json, "post").then(() => {
                        this.$message({
                            type: 'success',
                            message: '更新成功！'
                        });
                        data.zj_name = value;
                    });
                });
            },
            addNode(node, data) {
                let msg = "请输入章节名称";
                if (data.id === 0) {
                    msg = "请输入科目名称";
                }
                this.$prompt(msg, '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    inputValidator: (input) => {
                        return input.length <= 20
                    },
                    inputErrorMessage: '超出长度限制'
                }).then(({value}) => {
                    let json = {
                        zj_name: value,
                        parent_id: data.id
                    };
                    axiosApi("/api/sys/tiku/addClassify", json, "post").then((data) => {
                        this.$message({
                            type: 'success',
                            message: '《' + value + '》科目添加成功！'
                        });
                        node.loaded = false;
                        node.expand();
                    });
                });
            }
        }
    }
</script>

<style scoped>

    .show-hide:hover :nth-child(2) {
        display: inline-block !important;
    }

    /deep/ .el-drawer__body {
        overflow: auto;
    }

    /deep/ .el-drawer__body::-webkit-scrollbar {
        display: none;
    }

    /deep/ .el-upload-list__item.is-error .el-upload-list__item-name {
        color: red !important;
    }
</style>
