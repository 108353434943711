<template>
  <el-row style="height: 100%">
    <el-col :span="24">
      <el-card style="height: 100%">
        <el-drawer
            v-model="roleShow"
            :with-header="false">
          <el-form style="margin-top: 20px;margin-right: 10px" size="mini"
                   :model="role"
                   :rules="rules" ref="ruleForm"
                   label-width="150px">
            <el-form-item label="角色名称" prop="roleName">
              <el-input v-model="role.roleName"></el-input>
            </el-form-item>
            <el-form-item label="权限列表" prop="menuIds">
              <el-select v-model="role.menuIds" filterable multiple style="width: 100%">
                <el-option label="用户管理" :value="1"></el-option>
                <el-option label="角色管理" :value="2"></el-option>
                <el-option label="学员管理" :value="3"></el-option>
                <el-option label="题库管理" :value="4"></el-option>
                <el-option label="推荐人管理" :value="5"></el-option>
                <el-option label="考试管理" :value="6"></el-option>
                <el-option label="考试记录" :value="7"></el-option>
                <el-option label="章节练习记录" :value="8"></el-option>
                <el-option label="每日一练记录" :value="9"></el-option>
                <el-option label="错题本记录" :value="10"></el-option>
                <el-option label="我的收藏记录" :value="11"></el-option>
                <el-option label="视频管理" :value="12"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="submitForm">确定</el-button>
              <el-button @click="cancelForm">取消</el-button>
            </el-form-item>
          </el-form>

        </el-drawer>
        <el-row>
          <el-button size="mini" type="primary" @click="addRole">添加角色</el-button>
        </el-row>
        <el-row style="margin-top: 10px;">
          <el-table
              size="mini"
              :data="roles"
              stripe
              v-loading="loading"
              height="700px"
              style="width: 100%">
            <el-table-column prop="roleName" label="角色名称" width="200" show-overflow-tooltip>
            </el-table-column>
            <el-table-column label="权限列表" show-overflow-tooltip>
              <template #default="scope">
                <el-tag v-for="(item, index) in scope.row.menus" :key="item.id">{{ item.menuName }}</el-tag>
              </template>
            </el-table-column>
            <el-table-column prop="createDate" label="创建时间" width="200">
            </el-table-column>
            <el-table-column label="操作">
              <template #default="scope">
                <el-link :underline="false" href="#" type="primary"
                         @click="editRole(scope.row)"
                         style="font-size: 13px">
                  编辑
                </el-link>
                <el-link :underline="false" href="javascript:void(0)" type="danger"
                         @click="deleteRole(scope.row)"
                         style="font-size: 13px">
                  删除
                </el-link>
              </template>
            </el-table-column>
          </el-table>
        </el-row>
      </el-card>
    </el-col>
  </el-row>
</template>

<script>
import axiosApi from "@/http";

export default {
  name: "Role",
  data() {
    return {
      roles: [],
      loading: false,
      roleShow: false,
      rules: {
        roleName: [
          {required: true, message: '请输入角色名称', trigger: 'blur'},
          {max: 20, message: '长度最大20个字符', trigger: 'blur'}
        ],
        menuIds: [
          {required: true, message: '请选择权限', trigger: 'change'}
        ],
      },
      role: {
        id: 0,
        roleName: '',
        menuIds: [],
        menus: [],
      },
    };
  },
  mounted() {
    this.getRoles();
  },
  methods: {
    deleteRole(row) {
      this.$confirm('此操作将彻底删除角色“' + row.roleName + '”, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(({}) => {
        axiosApi("/api/sys/role/remove/" + row.id, null, "delete").then(() => {
          this.$message({
            type: 'success',
            message: '删除成功！'
          });
          this.getRoles();
        });
      });
    },
    editRole(row) {
      this.role = Object.assign({}, row);
      this.roleShow = true;
    },
    addRole(){
      this.role = {
        id: 0,
        roleName: '',
        menuIds: [],
        menus: [],
      };
      this.roleShow = true;
    },
    submitForm() {
      this.$refs['ruleForm'].validate((valid) => {
        if (valid) {
          let data = Object.assign({}, this.role);
          if (data.id !== 0) {
            axiosApi("/api/sys/role/update", data, "post").then(() => {
              this.$message({
                type: 'success',
                message: '修改成功！'
              });
              this.roleShow = false;
              this.getRoles();
            });
          } else {
            axiosApi("/api/sys/role/save", data, "post").then(() => {
              this.$message({
                type: 'success',
                message: '添加成功！'
              });
              this.roleShow = false;
              this.getRoles();
            });
          }
        } else {
          return false;
        }
      });
    },
    cancelForm() {
      this.roleShow = false;
    },
    getRoles() {
      this.loading = true;
      axiosApi("/api/sys/role/roleList", null, "get").then((data) => {
        this.roles = data;
        this.loading = false;
      });
    },
    handleSizeChange(val) {
      if (val != null) {
        this.pageSize = val;
      }
    },
    handleCurrentChange(val) {
      if (val != null) {
        this.currentPage = val;
      }
    },
  },
}
</script>

<style scoped>
/deep/ .el-link--inner {
  padding: 0 5px;
}

/deep/ .el-card__body {
  height: 100%;
}

.cell > span {
  margin-right: 5px;
}

</style>