import {createRouter, createWebHistory} from 'vue-router';
import Main from "@/components/Main";
import Login from "@/components/Login";
import whitePage from "@/components/whitePage";
import Tiku from "@/components/Tiku";
import User from "@/components/User";
import Role from "@/components/Role";
import Code from '@/components/Code';
import Referral from "@/components/Referral";
import Exam from "@/components/Exam";
import ExamRecord from "../components/ExamRecord";
import ZjRecord from "../components/ZjRecord";
import DayRecord from "../components/DayRecord";
import ScRecord from "../components/ScRecord";
import ErrorRecord from "../components/ErrorRecord";
import Video from "../components/Video";

const routes = [
    {
        path: '/',
        component: Login,
    },
    {
        path: '/main',
        name: 'main',
        component: Main,
        redirect: '/whitePage',
        children: [
            {
                path: "/whitePage",
                component: whitePage
            },
            {
                path: "/tiku",
                component: Tiku
            },
            {
                path: "/admin",
                component: User
            },
            {
                path: "/role",
                component: Role
            },
            {
                path: "/user",
                component: Code
            },
            {
                path: "/referral",
                component: Referral
            },
            {
                path: "/exam",
                component: Exam
            },
            {
                path: "/examRecord",
                component: ExamRecord
            },
            {
                path: "/zjRecord",
                component: ZjRecord
            },
            {
                path: "/dayRecord",
                component: DayRecord
            },
            {
                path: "/errorRecord",
                component: ErrorRecord
            },
            {
                path: "/scRecord",
                component: ScRecord
            },
            {
                path: "/video",
                component: Video
            }
        ]
    }
]

const router = createRouter(
    {
        history: createWebHistory(),
        scrollBehavior: () => ({y: 0}),
        routes: routes
    }
);

export default router


