<template>
    <el-row style="height: 100%">
        <el-col :span="24">
            <el-card style="height: 100%">
                <el-drawer
                        v-model="examShow"
                        :with-header="false">
                    <el-form style="margin-top: 20px;margin-right: 10px" size="mini"
                             :model="exam"
                             :rules="rules" ref="ruleForm"
                             label-width="150px">
                        <el-form-item label="试卷名称" prop="examName">
                            <el-input v-model="exam.examName"></el-input>
                        </el-form-item>
                        <el-form-item label="答题时长（分）" prop="examTime">
                            <el-input v-model.number="exam.examTime" type="number"></el-input>
                        </el-form-item>
                        <el-form-item label="及格线" prop="offscores">
                            <el-input v-model.number="exam.offscores" type="number"></el-input>
                        </el-form-item>
                        <el-form-item>
                            <el-button type="primary" @click="submitForm">确定</el-button>
                            <el-button @click="cancelForm">取消</el-button>
                        </el-form-item>
                    </el-form>

                </el-drawer>
                <el-row>
                    <el-button size="mini" type="primary" @click="addExam">添加试卷</el-button>
                </el-row>
                <el-row style="margin-top: 10px;">
                    <el-table
                            size="mini"
                            :data="tableData"
                            stripe
                            v-loading="loading"
                            height="700px"
                            style="width: 100%">
                        <el-table-column prop="examName" label="试卷名称" show-overflow-tooltip>
                        </el-table-column>
                        <el-table-column prop="examTime" label="答题时长（分）" show-overflow-tooltip>
                        </el-table-column>
                        <el-table-column prop="offscores" label="及格线" show-overflow-tooltip>
                        </el-table-column>
                        <el-table-column label="是否发布" show-overflow-tooltip>
                            <template #default="scope">
                                {{ scope.row.issueStatus === 1 ? '是' : '否' }}
                            </template>
                        </el-table-column>
                        <el-table-column prop="createDate" label="创建时间" width="180" show-overflow-tooltip>
                        </el-table-column>
                        <el-table-column label="操作">
                            <template #default="scope">
                                <el-link :underline="false" href="javascript:void(0)" type="primary"
                                         v-if="scope.row.issueStatus === 0"
                                         @click="editExam(scope.row)"
                                         style="font-size: 13px">
                                    编辑
                                </el-link>
                                <el-link :underline="false" type="success" href="javascript:void(0)"
                                         v-if="scope.row.issueStatus === 0"
                                         @click="issue(scope.row)"
                                         style="font-size: 13px">
                                    发布
                                </el-link>
                                <el-link :underline="false" href="javascript:void(0)" type="danger"
                                         @click="deleteExam(scope.row)"
                                         style="font-size: 13px">
                                    删除
                                </el-link>
                            </template>
                        </el-table-column>
                    </el-table>
                </el-row>
            </el-card>
        </el-col>
    </el-row>
</template>

<script>
    import axiosApi from "../http";

    export default {
        name: "Exam",
        data() {
            return {
                tableData: [],
                loading: false,
                examShow: false,
                exam: {
                    id: 0,
                    examName: '',
                    examTime: 120,
                    offscores: 80,
                },
                rules: {
                    examName: [
                        {required: true, message: '请输入', trigger: 'blur'},
                    ],
                    examTime: [
                        {required: true, message: '请输入', trigger: 'blur'},
                    ],
                    offscores: [
                        {required: true, message: '请输入', trigger: 'blur'},
                    ]
                },
                multipleSelection: [],
                searchContent: '',
                upload: false,
            };
        },
        mounted() {
            this.getExams();
        },
        methods: {
            deleteExam(row) {
                this.$confirm('删除后彻底无法恢复，是否删除试卷“' + row.examName + '”?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(({}) => {
                    axiosApi("/api/sys/exam/remove/" + row.id, null, "get").then(() => {
                        this.$message({
                            type: 'success',
                            message: '删除成功！'
                        });
                        this.getExams();
                    });
                });
            },
            issue(row) {
                this.$confirm('发布后无法再编辑或更新习题，是否发布试卷“' + row.examName + '”?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(({}) => {
                    axiosApi("/api/sys/exam/issue", {id: row.id}, "post").then(() => {
                        this.$message({
                            type: 'success',
                            message: '发布成功！'
                        });
                        this.getExams();
                    });
                });
            },
            editExam(row) {
                this.exam = Object.assign({}, row);
                this.examShow = true;
            },
            addExam() {
                this.exam.id = 0;
                this.examShow = true;
            },
            getExams() {
                this.loading = true;
                axiosApi("/api/sys/exam/list", null, "get").then((data) => {
                    this.tableData = data;
                    this.loading = false;
                });
            },
            submitForm() {
                this.$refs['ruleForm'].validate((valid) => {
                    if (valid) {
                        if (this.exam.id === 0) {
                            axiosApi("/api/sys/exam/save", this.exam, "post").then(() => {
                                this.$message({
                                    type: 'success',
                                    message: '添加成功！'
                                });
                                this.examShow = false;
                                this.getExams();
                            });
                        } else {
                            axiosApi("/api/sys/exam/update", this.exam, "post").then(() => {
                                this.$message({
                                    type: 'success',
                                    message: '编辑成功！'
                                });
                                this.examShow = false;
                                this.getExams();
                            });
                        }
                    } else {
                        return false;
                    }
                });
            },
            cancelForm() {
                this.examShow = false;
            },
        }
    }
</script>

<style scoped>

    /deep/ .el-link--inner {
        padding: 0 5px;
    }

</style>