<template>
    <el-row style="height: 100%">
        <el-col :span="24">
            <el-drawer
                    v-model="upload"
                    :with-header="false">
                <el-form style="margin-top: 20px;margin-right: 10px" size="mini"
                         label-width="150px">
                    <el-form-item label="选择文件">
                        <el-upload
                                accept="*"
                                ref="upload"
                                class="upload-demo"
                                action="/api/sys/video/save"
                                drag
                                :before-upload="beforeUpload"
                                :on-success="uploadSuccess"
                                :on-error="uploadError"
                        >
                            <i class="el-icon-upload"></i>
                            <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
                            <template #tip>
                                <div class="el-upload__tip">
                                    <span>只能上传.mp4、.3gp、.m3u8格式视频文件，文件大小不能超过2G</span>
                                </div>
                            </template>
                        </el-upload>
                    </el-form-item>
                </el-form>

            </el-drawer>
            <el-card style="height: 100%">
                <el-row>
                    <el-button size="mini" type="primary" @click="upload = true">导入视频</el-button>
                </el-row>
                <el-row style="margin-top: 10px;">
                    <el-table
                            size="mini"
                            :data="tableData"
                            stripe
                            v-loading="loading"
                            height="700px"
                            style="width: 100%">
                        <el-table-column label="视频名称" show-overflow-tooltip>
                            <template #default="scope">
                                <el-link :underline="false" :href="'https://akxfxx.cn/video/'+scope.row.address"
                                         type="primary" target="_blank"
                                         style="font-size: 13px">
                                    {{scope.row.videoName}}
                                </el-link>
                            </template>
                        </el-table-column>
                        <el-table-column prop="creator" label="上传人" show-overflow-tooltip>
                        </el-table-column>
                        <el-table-column prop="createDate" label="上传时间" width="180" show-overflow-tooltip>
                        </el-table-column>
                        <el-table-column label="操作">
                            <template #default="scope">
                                <el-link :underline="false" href="javascript:void(0)" type="danger"
                                         @click="deleteVideo(scope.row)"
                                         style="font-size: 13px">
                                    删除
                                </el-link>
                            </template>
                        </el-table-column>
                    </el-table>
                </el-row>
            </el-card>
        </el-col>
    </el-row>
</template>

<script>
    import axiosApi from "../http";

    export default {
        name: "Video",
        data() {
            return {
                tableData: [],
                loading: false,
                upload: false,
            };
        },
        mounted() {
            this.getVideos();
        },
        methods: {
            uploadError(err, file, fileList) {
                this.$message({
                    message: "网络请求异常，文件上传失败：" + file.name + '，失败原因：' + err,
                    type: 'error'
                })
                file.status = 'error'
                file.name = `${file.name}（上传失败）`
            },
            uploadSuccess(response, file, fileList) {
                if (response.code === 0) {
                    this.$message({
                        message: "文件上传成功：" + file.name,
                        type: 'success'
                    });
                    this.upload = false;
                    this.getVideos();
                } else {
                    this.$message({
                        message: "文件上传失败：" + file.name + '，失败原因：' + response.error,
                        type: 'error'
                    })
                    file.status = 'error'
                    file.name = `${file.name}（上传失败）`
                }
            },
            beforeUpload(file) {
                const hz = file.name.substring(file.name.lastIndexOf('.') + 1);
                const extension = hz === 'xlsx';
                const isLt300M = file.size / 1024 / 1024 <= 2048
                if (hz !== 'mp4' && hz !== '3gp' && hz !== 'm3u8') {
                    this.$message({
                        message: "只能上传.mp4、.3gp、.m3u8格式视频文件！",
                        type: 'error'
                    })
                    return false;
                }
                if (!isLt300M) {
                    this.$message({
                        message: "上传视频文件大小不能超过2G！",
                        type: 'error'
                    })
                    return false;
                }
                return true;
            },
            deleteVideo(row) {
                this.$confirm('删除后彻底无法恢复，是否删除视频“' + row.videoName + '”?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(({}) => {
                    axiosApi("/api/sys/video/remove/" + row.id, null, "get").then(() => {
                        this.$message({
                            type: 'success',
                            message: '删除成功！'
                        });
                        this.getVideos();
                    });
                });
            },
            getVideos() {
                this.loading = true;
                axiosApi("/api/sys/video/list", null, "get").then((data) => {
                    this.tableData = data;
                    this.loading = false;
                });
            },
        }
    }
</script>

<style scoped>

    /deep/ .el-link--inner {
        padding: 0 5px;
    }

</style>