<template>
    <div>欢迎登录安康市消防培训学校习题管理系统</div>
</template>

<script>
    export default {
        name: "whitePage"
    }
</script>

<style scoped>

</style>
