import axios from "axios";
import {ElMessage} from 'element-plus';

axios.defaults.headers.post["Content-Type"] = "application/json;charset=UTF-8";

axios.defaults.timeout = 60000;

axios.interceptors.request.use(
    config => {
        return config;
    },
    error => {
        ElMessage.error("网络请求异常！");
        return Promise.reject(error);
    }
);

axios.interceptors.response.use(
    response => {
        return response;
    },
    error => {
        ElMessage.error("网络响应异常！");
        return Promise.reject(error);
    }
);

export default function axiosApi(type, params, method) {
    let data = method === "post" ? JSON.stringify(params) : params
    return new Promise((resolve, reject) => {
        axios({
            method: method,
            url: type,
            data: data
        })
            .then(res => {
                if (res.data.code === 0) {
                    resolve(res.data.data)
                } else if (res.data.code === 402) {
                    window.location.href = '/';
                } else {
                    ElMessage.error(res.data.error);
                    reject(res.data);
                }
            })
            .catch(err => {
                reject(err)
            });
    })
};
