<template>
  <el-row style="height: 100%">
    <el-col :span="24">
      <el-card style="height: 100%">
        <el-drawer
            v-model="upload"
            :with-header="false">
          <el-form style="margin-top: 20px;margin-right: 10px" size="mini"
                   label-width="150px">
            <el-form-item label="选择文件">
              <el-upload
                  accept=".xlsx"
                  ref="upload"
                  class="upload-demo"
                  action="/api/sys/user/batchSave"
                  drag
                  :before-upload="beforeUpload"
                  :on-success="uploadSuccess"
                  :on-error="uploadError"
              >
                <i class="el-icon-upload"></i>
                <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
                <template #tip>
                  <div class="el-upload__tip">
                    <span>只能上传xlsx文件，文件大小不能超过100MB</span>
                  </div>
                  <el-link size="mini" href="/api/sys/user/downloadTemplate" type="primary" :underline="false">下载模板
                  </el-link>
                </template>
              </el-upload>
            </el-form-item>
          </el-form>

        </el-drawer>
        <el-drawer
            v-model="codeShow"
            :with-header="false">
          <el-form style="margin-top: 20px;margin-right: 10px" size="mini"
                   :model="user"
                   :rules="rules" ref="ruleForm"
                   label-width="150px">
            <el-form-item label="姓名" prop="name">
              <el-input v-model="user.name"></el-input>
            </el-form-item>
            <el-form-item label="性别" prop="sex">
              <el-radio-group v-model="user.sex">
                <el-radio label="男">男</el-radio>
                <el-radio label="女">女</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="身份证号" prop="cardId">
              <el-input v-model="user.cardId"></el-input>
            </el-form-item>
            <el-form-item label="手机号" prop="phone">
              <el-input v-model="user.phone"></el-input>
            </el-form-item>
            <el-form-item label="住址" prop="address">
              <el-input v-model="user.address"></el-input>
            </el-form-item>
            <el-form-item label="生效日期范围" prop="activationTime">
              <el-date-picker
                  v-model="user.activationTime"
                  type="daterange"
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期">
              </el-date-picker>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="submitForm">确定</el-button>
              <el-button @click="cancelForm">取消</el-button>
            </el-form-item>
          </el-form>

        </el-drawer>
        <el-row>
          <el-col :span="20">
            <el-button size="mini" type="primary" @click="addCode">添加学员</el-button>
            <el-button size="mini" type="success" @click="upload = true">导入学员</el-button>
            <el-button size="mini" type="warning" :disabled="multipleSelection.length !== 1" @click="editCode">
              编辑学员
            </el-button>
            <el-button size="mini" type="danger" :disabled="multipleSelection.length === 0" @click="deleteCode">
              {{ multipleSelection.length === 0 ? '删除学员' : '删除学员 (' + multipleSelection.length + ')' }}
            </el-button>
          </el-col>
          <el-col :span="4">
            <el-input
                v-model="searchContent"
                placeholder="搜索..."
                size="mini"
                style="float:right;">
            </el-input>
          </el-col>
        </el-row>
        <el-row style="margin-top: 10px;">
          <el-table
              size="mini"
              :data="tableData"
              stripe
              v-loading="userLoading"
              height="700px"
              @selection-change="handleSelectionChange"
              style="width: 100%">
            <el-table-column type="selection" width="55px"></el-table-column>
            <el-table-column prop="name" label="姓名" show-overflow-tooltip>
            </el-table-column>
            <el-table-column prop="sex" label="性别" show-overflow-tooltip>
            </el-table-column>
            <el-table-column prop="cardId" label="身份证号" width="180" show-overflow-tooltip>
            </el-table-column>
            <el-table-column prop="phone" label="手机号" show-overflow-tooltip>
            </el-table-column>
            <el-table-column prop="address" label="住址" show-overflow-tooltip>
            </el-table-column>
            <el-table-column prop="codeValue" label="激活码" show-overflow-tooltip>
            </el-table-column>
            <el-table-column label="是否激活" show-overflow-tooltip>
              <template #default="scope">
                {{ scope.row.status === '1' ? '已激活' : '未激活' }}
              </template>
            </el-table-column>
            <el-table-column prop="createDate" label="创建时间" width="200" sortable>
            </el-table-column>
            <el-table-column prop="effectiveDate" :formatter="formatterTime" label="生效日期" width="200" sortable>
            </el-table-column>
            <el-table-column prop="failureDate" :formatter="formatterTime" label="截止日期" width="200" sortable>
            </el-table-column>
          </el-table>
        </el-row>
      </el-card>
    </el-col>
  </el-row>
</template>

<script>
import axiosApi from "@/http";

export default {
  name: "Code",
  data() {
    return {
      users: [],
      tableData: [],
      userLoading: false,
      codeShow: false,
      user: {
        name: '',
        phone: '',
        cardId: '',
        address: '',
        activationTime: [],
        sex: '男',
      },
      rules: {
        name: [
          {required: true, message: '请输入', trigger: 'blur'},
        ],
        phone: [
          {required: true, message: '请输入', trigger: 'blur'},
        ],
        cardId: [
          {required: true, message: '请输入', trigger: 'blur'},
        ],
        // address: [
        //   {required: true, message: '请输入', trigger: 'blur'},
        // ],
        sex: [
          {required: true, message: '请选择', trigger: 'change'},
        ],
        activationTime: [
          {required: true, message: '请选择', trigger: 'change'},
        ],
      },
      multipleSelection: [],
      searchContent: '',
      upload: false,
    };
  },
  mounted() {
    this.getUsers();
  },
  watch: {
    searchContent: function () {
      let input = this.searchContent;
      let items = this.users;
      if (input) {
        this.tableData = items.filter(function (item) {
          return Object.keys(item).some(function (key1) {
            return String(item[key1]).match(input);
          });
        });
      } else {
        this.tableData = items;
      }
    }
  },
  methods: {
    formatterTime(row,column){
      let data = row[column.property]
      return  /\d{4}-\d{1,2}-\d{1,2}/g.exec(data )
    },
    uploadError(err, file, fileList) {
      this.$message({
        message: "网络请求异常，文件上传失败：" + file.name + '，失败原因：' + err,
        type: 'error'
      })
      file.status = 'error'
      file.name = `${file.name}（上传失败）`
    },
    uploadSuccess(response, file, fileList) {
      if (response.code === 0) {
        this.$message({
          message: "文件上传成功：" + file.name,
          type: 'success'
        });
        this.upload = false;
        this.getUsers();
      } else {
        this.$message({
          message: "文件上传失败：" + file.name + '，失败原因：' + response.error,
          type: 'error'
        })
        file.status = 'error'
        file.name = `${file.name}（上传失败）`
      }
    },
    beforeUpload(file) {
      const hz = file.name.substring(file.name.lastIndexOf('.') + 1);
      const extension = hz === 'xlsx';
      const isLt100M = file.size / 1024 / 1024 < 100
      if (!extension) {
        this.$message({
          message: "上传文件只能是xlsx格式！",
          type: 'error'
        })
        return false;
      }
      if (!isLt100M) {
        this.$message({
          message: "上传文件大小不能超过100M！",
          type: 'error'
        })
        return false;
      }
      return true;
    },
    addCode() {
      this.user.id = 0;
      this.codeShow = true;
    },
    editCode() {
      this.user = Object.assign({}, this.multipleSelection[0]);
      this.user.activationTime = [this.user.effectiveDate, this.user.failureDate];
      this.codeShow = true;
    },
    deleteCode() {
      this.$confirm('当前选中了' + this.multipleSelection.length + '个学员, 是否全部删除?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(({}) => {
        let ids = [];
        for (let i = 0; i < this.multipleSelection.length; i++) {
          ids.push(this.multipleSelection[i].id)
        }
        axiosApi("/api/sys/user/batchRemove", ids, "post").then(() => {
          this.$message({
            type: 'success',
            message: '删除成功！'
          });
          this.getUsers();
        });
      });
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    submitForm() {
      this.$refs['ruleForm'].validate((valid) => {
        this.user.effectiveDate = this.user.activationTime[0];
        this.user.failureDate = this.user.activationTime[1];
        if (valid) {
          if (this.user.id === 0) {
            axiosApi("/api/sys/user/save", this.user, "post").then(() => {
              this.$message({
                type: 'success',
                message: '添加成功！'
              });
              this.codeShow = false;
              this.getUsers();
            });
          } else {
            axiosApi("/api/sys/user/update", this.user, "post").then(() => {
              this.$message({
                type: 'success',
                message: '编辑成功！'
              });
              this.codeShow = false;
              this.getUsers();
            });
          }
        } else {
          return false;
        }
      });
    },
    cancelForm() {
      this.userShow = false;
    },
    getUsers() {
      this.userLoading = true;
      axiosApi("/api/sys/user/list", null, "get").then((data) => {
        this.users = data;
        this.tableData = data;
        this.userLoading = false;
      });
    },
  },
}
</script>

<style scoped>

/deep/ .el-link--inner {
  padding: 0 5px;
}

/deep/ .el-card__body {
  height: 100%;
}

</style>
