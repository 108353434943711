<template>
  <el-container style="height: 100%">
    <el-drawer
            v-model="pwdShow"
            :with-header="false">
      <el-form style="margin-top: 20px;margin-right: 10px" size="mini"
               :model="password"
               :rules="rules" ref="ruleForm"
               label-width="150px">
        <el-form-item label="旧密码" prop="old">
          <el-input v-model="password.old"></el-input>
        </el-form-item>
        <el-form-item label="新密码" prop="new">
          <el-input v-model="password.new"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="submitForm">确定</el-button>
          <el-button @click="cancelForm">取消</el-button>
        </el-form-item>
      </el-form>

    </el-drawer>
    <el-header>
      <el-menu
          class="el-menu-demo"
          mode="horizontal"
          background-color="#545c64"
          text-color="#fff"
          active-text-color="#fff">
        <el-menu-item index="1" route="/main">
          <img src="../assets/logo.png" style="width: 60px;height: 52px" alt="">
          安康市消防职业技能培训学校
        </el-menu-item>
        <el-submenu index="2" style="float: right">
          <template v-slot:title>{{ username }}</template>
          <el-menu-item @click="exit" index="1-1" style="text-align: center">退出</el-menu-item>
          <el-menu-item @click="modifyPassword" index="1-2" style="text-align: center">修改密码</el-menu-item>
        </el-submenu>
      </el-menu>
    </el-header>
    <el-container>
      <el-aside width="150px">
        <el-menu
            :uniqueOpened="true"
            :default-active="menuName"
            style="height: 100%"
            background-color="#545c64"
            text-color="#fff"
            active-text-color="#ffd04b"
            router>
          <el-menu-item v-for="(item, index) in menus" v-bind:key="index" :index="'/'+item.permission">{{ item.menuName }}</el-menu-item>
        </el-menu>
      </el-aside>
      <el-main style="height: 100%;background-color: #f8f6f6;">
        <router-view></router-view>
      </el-main>
    </el-container>
  </el-container>
</template>

<script>
import axiosApi from "@/http";

export default {
  name: "Main",
  data() {
    return {
      password:{
        old:'',
        new:''
      },
      rules:{
        old: [{required: true, message: '请输入密码...', trigger: 'blur'}],
        new: [{required: true, message: '请输入密码...', trigger: 'blur'}]
      },
      pwdShow:false,
      username: '',
      menuName:'',
      menus: [],
    };
  },
  mounted() {
    // console.log(this.$route.path)
    this.menuName = this.$route.path
    axiosApi("/api/getInfo", null, "get").then((data) => {
      this.username = data[0];
      this.menus = data[1];
    });
  },
  methods: {
    modifyPassword(){
      this.pwdShow = true;
      this.password = {
        old:'',
        new:''
      }
    },
    cancelForm() {
      this.pwdShow = false;
    },
    submitForm(){
      axiosApi("/api/sys/admin/updatePwd", {
        old:this.password.old,
        new:this.password.new
      }, "post").then(() => {
        this.$alert('修改成功，请重新登录', '提示', {
          confirmButtonText: '确定',
          callback: () => {
            this.exit()
          }
        });
      });
    },
    exit() {
      axiosApi("/api/logout", null, "get").then(() => {
        window.location.href = '/';
      });
    }
  }
}
</script>

<style scoped>
.el-header {
  padding: 0;
}

.el-container {
  height: 100%;
}
</style>
