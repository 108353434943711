<template>
  <el-row style="height: 100%">
    <el-col :span="24">
      <el-card style="height: 100%">
        <el-drawer
            v-model="userShow"
            :with-header="false">
          <el-form style="margin-top: 20px;margin-right: 10px" size="mini"
                   :model="user"
                   :rules="rules" ref="ruleForm"
                   label-width="150px">
            <el-form-item label="账号名称" prop="username">
              <el-input v-model="user.username"></el-input>
            </el-form-item>
            <el-form-item label="手机号" prop="phone">
              <el-input v-model="user.phone"></el-input>
            </el-form-item>
            <el-form-item label="角色列表" prop="roleIds">
              <el-select v-model="user.roleIds" filterable multiple>
                <el-option v-for="(item, index) in roles" :label="item.roleName" :value="item.id"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="submitForm">确定</el-button>
              <el-button @click="cancelForm">取消</el-button>
            </el-form-item>
          </el-form>

        </el-drawer>
        <el-row>
          <el-button size="mini" type="primary" @click="addUser">添加用户</el-button>
        </el-row>
        <el-row style="margin-top: 10px;">
          <el-table
              size="mini"
              :data="admins"
              stripe
              v-loading="adminLoading"
              height="700px"
              style="width: 100%">
            <el-table-column prop="username" label="账号" width="200" show-overflow-tooltip>
            </el-table-column>
            <el-table-column label="角色" show-overflow-tooltip>
              <template #default="scope">
                <el-tag v-for="(item, index) in scope.row.roles" :key="item.id">{{ item.roleName }}</el-tag>
              </template>
            </el-table-column>
            <el-table-column prop="phone" label="手机号" width="200">
            </el-table-column>
            <el-table-column prop="createDate" label="创建时间" width="200">
            </el-table-column>
            <el-table-column prop="loginDate" label="最后登录时间" width="200">
            </el-table-column>
            <el-table-column label="操作">
              <template #default="scope">
                <el-link :underline="false" href="javascript:void(0)" type="primary"
                         @click="editUser(scope.row)"
                         style="font-size: 13px">
                  编辑
                </el-link>
                <el-link :underline="false" href="javascript:void(0)" type="success"
                         @click="resetPwd(scope.row)"
                         style="font-size: 13px">
                  重置密码
                </el-link>
                <el-link :underline="false" href="javascript:void(0)" type="danger"
                         @click="deleteUser(scope.row)"
                         style="font-size: 13px">
                  删除
                </el-link>
              </template>
            </el-table-column>
          </el-table>
        </el-row>
      </el-card>
    </el-col>
  </el-row>
</template>

<script>
import axiosApi from "@/http";

export default {
  name: "User",
  data() {
    return {
      admins: [{}],
      adminLoading: false,
      userShow: false,
      user: {
        id: 0,
        username: '',
        phone: '',
        roleIds: [],
      },
      roles: [],
      rules: {
        username: [
          {required: true, message: '请输入账号名称', trigger: 'blur'},
          {max: 20, message: '长度最大20个字符', trigger: 'blur'}
        ],
        roleIds: [
          {required: true, message: '请选择角色', trigger: 'change'},
        ],
        phone: [
          {required: true, message: '请输入手机号码', trigger: 'blur'},
          {max: 20, message: '长度最大20个字符', trigger: 'blur'}
        ],
      },
    };
  },
  mounted() {
    this.getAdmins();
  },
  methods: {
    deleteUser(row) {
      this.$confirm('此操作将彻底删除用户“' + row.username + '”, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(({}) => {
        axiosApi("/api/sys/admin/remove/" + row.id, null, "get").then(() => {
          this.$message({
            type: 'success',
            message: '删除成功！'
          });
          this.getAdmins();
        });
      });
    },
    resetPwd(row) {
      this.$confirm('此操作将重置用户“' + row.username + '”的密码, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(({}) => {
        axiosApi("/api/sys/admin/resetPwd", row.id, "post").then(() => {
          this.$message({
            type: 'success',
            message: '重置成功！'
          });
        });
      });
    },
    editUser(row) {
      axiosApi("/api/sys/role/list", null, "get").then((data) => {
        this.roles = data;
        this.user = Object.assign({}, row);
        this.userShow = true;
      });
    },
    addUser() {
      this.user = {
        id: 0,
        username: '',
        phone: '',
        roleIds: [],
      };
      axiosApi("/api/sys/role/list", null, "get").then((data) => {
        this.roles = data;
        this.userShow = true;
      });
    },
    submitForm() {
      this.$refs['ruleForm'].validate((valid) => {
        if (valid) {
          let data = Object.assign({}, this.user);
          if (data.id !== 0) {
            axiosApi("/api/sys/admin/update", data, "post").then(() => {
              this.$message({
                type: 'success',
                message: '修改成功！'
              });
              this.userShow = false;
              this.getAdmins();
            });
          } else {
            axiosApi("/api/sys/admin/save", data, "post").then(() => {
              this.$message({
                type: 'success',
                message: '添加成功！'
              });
              this.userShow = false;
              this.getAdmins();
            });
          }
        } else {
          return false;
        }
      });
    },
    cancelForm() {
      this.userShow = false;
    },
    getAdmins() {
      this.adminLoading = true;
      axiosApi("/api/sys/admin/list", null, "get").then((data) => {
        this.admins = data;
        this.adminLoading = false;
      });
    },
    handleSizeChange(val) {
      if (val != null) {
        this.pageSize = val;
      }
    },
    handleCurrentChange(val) {
      if (val != null) {
        this.currentPage = val;
      }
    },
  },
}
</script>

<style scoped>
/deep/ .el-link--inner {
  padding: 0 5px;
}

/deep/ .el-card__body {
  height: 100%;
}

.cell > span {
  margin-right: 5px;
}

</style>